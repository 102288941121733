.form{
	
	&__group{
		margin-top: 15px !important;
		margin-left: -10px !important;
        &:not(:last-child){
            margin-bottom: 2rem;
        }
    }

    &__input{
        font-size: 1.5rem;
        padding: 1.5rem 2rem;
        border-radius: 3px;
        -webkit-border-radius: 3px;
        -moz-border-radius: 3px;
        -ms-border-radius: 3px;
        -o-border-radius: 3px;
        background-color: rgba(#fefefe, .5);
        border: none;
        font-family: 'Poppins', sans-serif;
        color: inherit;
        border-bottom: 3px solid  transparent;
        width: 90%;
        display: block;
        transition: all .3s;
        -webkit-transition: all .3s;
        -moz-transition: all .3s;
        -ms-transition: all .3s;
        -o-transition: all .3s;

        &::-webkit-input-placeholder{
            color: #999;
        }

        &:focus{
            outline: none;
            box-shadow: 0 1rem 2rem rgba(#000, .15);
            border-bottom: 3px solid  #783e34;
        }

        &:focus:invalid{
            border-bottom: 3px solid  #45556f;
             
        }

}

    &__label{
        font-size: 1.2rem;
        font-weight: 700;
        margin-left: 2rem;
        margin-top: .7rem;
        display: block;
        transition: all .3s;
        -webkit-transition: all .3s;
        -moz-transition: all .3s;
        -ms-transition: all .3s;
        -o-transition: all .3s;
}
    
    &__input:placeholder-shown + &__label{
        opacity: 0;
        visibility: hidden;
        transform: translateY(-4rem);
        -webkit-transform: translateY(-4rem);
        -moz-transform: translateY(-4rem);
        -ms-transform: translateY(-4rem);
        -o-transform: translateY(-4rem);
}

    &__radio-group{
        width: 49%;
        display: inline-block;
    }

    &__radio-input{
        visibility: hidden;
    }

    &__radio-label{
        font-size: 16px;
        cursor: pointer;
        position: relative;
        padding-left:  3.5rem;
    }

    &__radio-button{
        height: 2rem;
        width: 2rem;
        border: 3px solid #783e34;
        border-radius: 50%;
        display: inline-block;
        position: absolute;
        left: 0;
        top: -.4rem;

        &::after{
            content: "";
            display: block;
            height: 1rem;
            width: 1rem;
            opacity: 0;
            position: absolute;
            left: 50%;
            top: 50%;
            background-color: #783e34;

            border-radius: 50%;
            -webkit-border-radius: 50%;
            -moz-border-radius: 50%;
            -ms-border-radius: 50%;
            -o-border-radius: 50%;

            transform: translate(-50%, -50%);
            -webkit-transform: translate(-50%, -50%);
            -moz-transform: translate(-50%, -50%);
            -ms-transform: translate(-50%, -50%);
            -o-transform: translate(-50%, -50%);
            
            transition: opacity .3s;
            -webkit-transition: opacity .3s;
            -moz-transition: opacity .3s;
            -ms-transition: opacity .3s;
            -o-transition: opacity .3s;
        }
    }

    &__radio-input:checked + &__radio-label &__radio-button{
        &::after{
            opacity: 1;
        }
    }
}