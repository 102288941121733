.main-contact-container {
  .thank-you-section {
    .contact-us-style {
      background: linear-gradient(rgba(5, 15, 24, 0.5), rgba(26, 9, 19, 0.3)),
        url(../../../../assets/images/contact/ethera-irvine.jpg);
      width: 100%;
      height: 80vh;
      position: relative;
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      align-items: center;
      display: flex;
      // border-bottom-left-radius: 20%;
      // border-bottom-right-radius: 20%;
      @include breakpoint-up(xxxLarge) {
        height: 50vh;
      }
      @include breakpoint-down(med) {
        height: 40vh;
      }
      @include breakpoint-down(xSmall) {
        height: 30vh;
      }
      h1 {
        font-size: 65px;
        color: white;
        align-items: center;
        display: flex;
        padding: 0rem 4rem;
        @include breakpoint-down(med) {
          font-size: 45px;
          padding: 0rem 2rem;
        }
        @include breakpoint-down(small) {
          font-size: 30px;
        }
      }
    }
    .contact-container {
      position: relative;
      .content-container {
        max-width: 1100px;
        margin: auto;
        padding: 10rem 2rem;
        @include breakpoint-down(med) {
          padding: 5rem 2rem;
        }

        h1 {
          font-size: 60px;
          color: #2e2e39;
          font-family: 'Athelas', Sans-serif !important;
          @include breakpoint-down(med) {
            font-size: 40px;
          }
          @include breakpoint-down(small) {
            font-size: 30px;
          }
        }
        p {
          font-size: 24px;
          font-weight: 400;
          font-family: 'Athelas', Sans-serif !important;
          @include breakpoint-down(small) {
            font-size: 20px;
          }
        }
        h6 {
          color: #2e2e39;
          line-height: 2.2rem;
        }
      }
      .jelly {
        position: absolute;
        left: 0;
        top: -175px;
        @include breakpoint-down(large11) {
          width: 15%;
        }
        @include breakpoint-down(large) {
          display: none;
        }
      }
    }
  }
  .address-section {
    padding: 50px 0px;
    box-shadow: 0px 4px 18px 0px rgba(117, 49, 51.99999999999999, 0.23);
    background: linear-gradient(rgb(255 247 236 / 90%), rgb(255 247 239 / 90%)),
      url(../../../../assets/images/contact/img-1.jpg);
    width: 100%;
    position: relative;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    .address-container {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      max-width: 1100px;
      margin: auto;
      .address-box {
        width: 50%;
        display: flex;
        align-items: center;
        padding: 1rem 1rem;
        gap: 1rem;
        @include breakpoint-down(small) {
          width: 100%;
        }
        img {
          width: 30%;
          @include breakpoint-down(small) {
            width: 20%;
          }
        }
        a {
          color: #2c446a;
          font-weight: 600;
          font-size: 20px;
          @include breakpoint-down(small) {
            font-size: 16px;
          }
        }
        p {
          font-weight: 300;
          font-size: 18px;
          color: #2c446a;
          @include breakpoint-down(small) {
            font-size: 14px;
          }
        }
      }
    }
  }
  .contact-form {
    .contact-container {
      max-width: 1100px;
      margin: auto;
      text-align: center;
      padding: 7rem 0rem 0rem 0rem;
      h1 {
        font-size: 40px;
        font-weight: 600;
        color: #222;
        @include breakpoint-down(small) {
          font-size: 25px;
        }
      }
      form {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        padding: 2rem 0rem;
        div {
          padding: 0rem 1rem;
          text-align: left;
          width: 50%;
          @include breakpoint-down(small) {
            width: 100%;
          }
        }
        .full-width {
          text-align: left;
          width: 100%;
        }
        .submit-btn-div {
          margin-left: auto;
          @include breakpoint-down(small) {
            width: 50%;
          }
          padding: 0rem 1.5rem;
          width: 25%;
        }
      }
    }
  }
  .join-section {
    background: url(../../../../assets/images/contact/Background-1.png);
    width: 100%;
    position: relative;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    .join-container {
      text-align: center;
      max-width: 1100px;
      margin: auto;
      padding: 10rem 2rem;
      @include breakpoint-down(xSmall) {
        padding: 5rem 0.5rem;
      }
      @media (max-width: 375px) {
        display: flex;
        flex-direction: column;
        gap: 1rem;
      }
      h1 {
        font-size: 50px;
        color: #2e2e39;
        font-weight: bold;

        padding: 0rem 0rem 4rem 0rem;
        @include breakpoint-down(med) {
          font-size: 30px;
          padding: 0rem 0.5rem 4rem 0.5rem;
        }
        @include breakpoint-down(small) {
          font-size: 20px;
        }
      }

      a {
        padding: 2rem 5rem;
        margin: 0rem 1rem;
        border: none;
        border-radius: 50px;
        color: #fff;
        @include breakpoint-down(large) {
          padding: 1.5rem 4rem;
        }
        @include breakpoint-down(small) {
          padding: 1rem 3rem;
          margin: 0rem 0.5rem;
        }
        @include breakpoint-down(xSmall) {
          padding: 1rem 2rem;
        }
      }
      .contact-request-btn {
        background: #d26820 !important;
      }
      .apply-btn {
        background: #126d9f !important;
      }
    }
  }
  footer {
    padding: 0rem 2rem;
  }
}
