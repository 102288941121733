.AppointmentCard {
  background-color: $bg-ethera;
  padding-top: 2rem;
  .appointcardBody {
    padding: 0rem 0rem !important;
    .setCards {
      margin: auto;
    }
  }
  .AppointmentHead {
    display: flex;
    justify-content: space-between;
    align-items: center;
    // padding-bottom: 0rem !important;
    .AppointmentTitleSet{
      font-size: 20px !important;
    }
    .setTitle {
      font-weight: bold;
      color: $black;
    }
  }
}

/////////////////////////////// PrevCard

.prevCard {
  border: 1px solid $ethera-gray;
  border-radius: 10px !important;
  padding: 0 !important;

  & svg {
    width: 15px;
    height: 15px;
    margin-right: 5px;

    @include breakpoint-up(xxxMLarge) {
      width: 20px !important;
      height: 20px !important;
    }
  }

  &--date {
    font-size: 14px;

    @include breakpoint-up(xxxSmall) {
      font-size: 16px;
    }

    @include breakpoint-up(xxLarge) {
      font-size: 18px;
    }

    @include breakpoint-up(xxxMLarge) {
      font-size: 18px;
      margin-top: 5px;
    }

    color: rgba(black,0.6);
    & span {
      font-weight: bold;
    }
  }

  .PrevGroup {
    border-radius: 10px !important;

    .prevCardHead {
      background-color: $ethera-prevcard-bgColor;
      border-radius: 10px;

      padding: 0.3rem 2rem !important;
      // padding-bottom: 1px !important;
      .PrevSize {
        font-size: 12px;
        margin-bottom: 0px;

        @include breakpoint-up(xxxMLarge) {
          font-size: 16px;
        }
      }
    }
    .prevCardItem {
      padding-bottom: 0 !important;
      padding-left: 2rem !important;
      .checkBtn {
        margin-top: -10px;
        font-size: 10px;
        background-color: $ethera-darkgreen !important;

        @include breakpoint-up(xxxMLarge) {
          border-radius: 50px;
          font-size: 14px;
          // padding: 10px 25px;
        }
      }
      .sizing {

        margin-top: -09px;
        font-size: 10px;
        margin-bottom: 4px;

        @include breakpoint-up(xxxMLarge) {
          font-size: 14px;
          margin-bottom: 10px;
        }
      }
      .setIcon {
        margin-top: -4px !important;
      }
      .setDeatils {
        padding-left: 0.3rem !important;

        & h6 {
          font-size: 14px;
          font-weight: 600;

          @include breakpoint-up (xxxMLarge) {
            font-size: 15px;
            margin-bottom: 8px;

          }
        }
      }
    }
  }
}
.nextCardHead {
  background-color: $ethera-nextcard-bgColor;
  border-radius: 10px;

  padding: 0.3rem 2rem !important;
  .PrevSize {
    font-size: 12px;
    margin-bottom: 0px;

    @include breakpoint-up(xxxMLarge) {
      font-size: 16px;
    }
  }
}
.spinner-size-lg{
  width: 20px;
  height: 20px;
}