.provider-card-bgColor {
  background-color: $ether-calendar-active !important;
  max-width: 36rem !important;
  margin: auto !important;
}
.provider-cardbody {
  background-color: $ethera-provider-card !important;
  border-bottom-left-radius: 18px !important;
  border-bottom-right-radius: 18px !important;
}
.ethera-link{
  text-decoration: underline !important;
  color: black !important;
}

.ethera-email {
  color: black !important;
  text-decoration: none !important;
}
.card-profile {
  border-radius: 19px !important;
  border: none !important;
  margin-bottom: 0 !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
  padding-top: 1.2rem !important;
}
.card-profile .card-body .profile-image-wrapper .profile-image {
  box-shadow: none !important;
  padding: 0 !important;
}
.provider-card-box {
  flex-wrap: wrap !important;
  display: flex;
  justify-content: space-between;
}
.provider-details {
  text-align: left !important;
}
.about-provider-details{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.bullets-color{
  color: #455470 !important  ;
  font-size: 20px;
  .li-text{
    color: $black;
    font-size: 15px;
  }
}