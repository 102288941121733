.chat-box {
  background-color: $ethera-provider-card;
  height: 100%;
  // width: 75%;
  margin: auto;
  margin: 2rem auto;
  border-radius: 20px;
  padding: 2rem 0rem;
  display: flex;
  flex-direction: column;

  @include breakpoint-up(small) {
    width: 75%;
  }

  @include breakpoint-up(medLarge) {
    width: 70%;
  }

  @include breakpoint-up(xLarge) {
    width: 700px;
  }

  @include breakpoint-up(xxxMLarge) {
    width: 900px;
  }

  .chat-message {
    height: 35rem;
    background-color: transparent;
    scroll-behavior: smooth;
    overflow-y: scroll;
    display: inline-grid;
    padding: 1.2rem;

    &--date {
      @include flex-center;
      width: fit-content;
      margin-left: auto;
      margin-right: auto;
      padding-left: 35px;
      padding-right: 35px;
      border-radius: 50px;
      height: 35px;
      margin-bottom: 10px;

      background-color: white;
    }

    .image-left-direction {
      display: flex;
      .chat-box-div {
        display: flex;
        align-items: flex-start;
        gap: 0.5rem;
      }
    }
    .image-right-direction {
      align-self: flex-end;
      margin-top: 1rem;
      .chat-box-div {
        display: flex;
        flex-direction: row-reverse;
        align-items: flex-start;
        gap: 0.5rem;
      }
    }
  }
  .editor {
    flex: 1;
    padding: 1.2rem;
  }
  .alert-danger {
    background: transparent !important;
    padding: 1rem 0.8rem 1rem 0.4rem;
    text-align: justify;
  }
}
.rdw-editor-toolbar {
  margin-bottom: 0px !important;
  border: none !important;
}
.rdw-editor-main {
  background-color: $white !important;
  border: none;
  padding: 0rem 0.8rem;
  height: 120px !important;
  overflow-y: scroll !important;
}
.rdw-editor-wrapper {
  border: 0.4px solid #999;
  display: flex;
  flex-direction: column-reverse;
}
.receiver-message-box {
  max-width: 19rem;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  font-size: 10px;
  width: fit-content;
  margin-right: auto;
  min-width: 160px;

  &--div {
    border-bottom-right-radius: 8px;
    border-top-right-radius: 8px;
    background-color: #f6dd97;
    padding: 0.8rem 0.6rem;
  }

  &--time {
    width: 100%;
    // background: red;
    display: flex;
    & span {
      margin-left: auto;
      font-size: 8px;
      margin-top: 1px;
      margin-right: 10px;

      @include breakpoint-up(xxxMLarge) {
        font-size: 10px;
      }
    }
  }

  & p {
    margin: 0px;
  }

  // &:not(:first-child) {
  //   margin-top: 0.6rem;
  // }

  @include breakpoint-up(xxLarge) {
    max-width: 21rem;
  }

  @include breakpoint-up(xxxMLarge) {
    max-width: 27rem;

    & p {
      font-size: 14px;
    }
  }
}
.receiver-message-box:first-child {
  border-top-right-radius: 12px;
}
.receiver-message-box:last-child {
  border-bottom-right-radius: 12px;
}
.sender-message-box {
  max-width: 19rem;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  font-size: 10px;
  width: fit-content;
  margin-left: auto;
  min-width: 160px;

  &--div {
    padding: 0.8rem 0.6rem;
    background-color: $ethera-nextcard-bgColor !important;
    border-bottom-left-radius: 8px;
    border-top-left-radius: 8px;
    
  }

  &--time {
    width: 100%;
    // background: red;
    display: flex;
    & span {
      margin-left: auto;
      font-size: 8px;
      margin-top: 1px;
      margin-right: 10px;

      @include breakpoint-up(xxxMLarge) {
        font-size: 10px;
      }
    }
  }

  & p {
    margin: 0px;
  }

  // &:not(:first-child) {
  //   margin-top: 0.6rem;
  // }

  @include breakpoint-up(xxLarge) {
    max-width: 21rem;
  }

  @include breakpoint-up(xxxMLarge) {
    max-width: 27rem;

    & p {
      font-size: 14px;
    }
  }
}
.error-msg {
  color: red !important;
}
.chat-message::-webkit-scrollbar {
  width: 8px;
}
.chat-message::-webkit-scrollbar-track {
  box-shadow: inset 0 0 25px #dfdfdf;
  border-radius: 12px;
}
.chat-message::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 20px #6281ae;
  border-radius: 12px;
}
.editorClassName div:first-child {
  height: auto;
}