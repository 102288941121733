//////////////////////////// Phone Screen //////////////////
@media screen and (max-width: 390px) {
  .sm-screen-ml-auto {
    // @include breakpoint-down(med) {
    margin-left: auto !important;
    // }
  }
  .check-in-section {
    display: block;
  }
  .checkBtn {
    margin: 1rem 0 !important;
  }
}
@media screen and (max-width: 450px) {
  .radio-setting {
    display: block;
  }

  .check-in {
    margin: 0;
    white-space: nowrap;
  }
  .gender-mt {
    position: relative !important;
  }
  .tableSetting {
    padding-right: 15px !important;
  }
  .AppointmentTitle {
    width: 100%;
    padding: 1rem 0rem 0rem 0rem !important;
  }
  .messages-card-body {
    margin-left: 0rem !important;
    margin-right: 0rem !important;
    padding-left: 0rem !important;
    padding-right: 0rem !important;
  }
  .request-btn {
    font-size: 15px !important;
    text-align: left;
    width: 100% !important;
    margin-left: 0 !important;
    // padding: 0.7rem 0rem;
    &::before {
      content: '+';
      margin-right: 2.2rem !important;
      padding: 1px 6px;
    }
  }
  .billingEditBtn {
    font-size: 13px !important;
    width: 100% !important;
    margin-left: 0 !important;
  }
  .billingBtnDiv {
    width: 100%;
  }
  .AppointmentHead {
    padding: 1.5rem 1rem !important;
  }
  .header-navbar {
    border-bottom-left-radius: 30px !important;
    border-bottom-right-radius: 30px !important;
  }

  .searchDiv {
    font-size: 18px;
    margin-top: 0 !important;
  }
  .billingSelect {
    font-size: 18px;
  }
  .request-btn-div {
    width: 100%;
    margin-top: 0 !important;
    margin-bottom: 1rem !important;
  }
  .insuranceForm {
    padding: 0rem 0rem !important;
  }
  .demo-inline-spacing {
    display: inline-grid;
  }
  .review-btn-div {
    // margin-right: 1rem;
    button {
      margin-left: 0 !important;
    }
  }

  .delete-btn {
    width: 100%;
    margin: 0.4rem 0rem;
  }
  .cancel-btn {
    width: 100%;
    margin-left: 0rem !important;
  }
  .save-btn {
    width: 100%;
    margin: 0.5rem 0rem !important;
  }
  .button-grp {
    width: 100%;
    margin: 0.2 rem 0rem;
  }

  .chat-box {
    width: 100%;
    .chat-message {
      padding: 0.5rem;

      &--date {
        background-color: $ethera-provider-card;
      }
    }
  }
  // .card-body-styling {
  //   margin-right: 1.4rem;
  //   margin-left: 1.4rem;
  // }
  .card .card-title {
    font-size: 1.1rem;
  }
  .ml--7 {
    margin-left: 0px;
  }
  .ModalDialoge .ModalFooter-pay .ModalAdd {
    width: 100%;
    background-color: #79a5ca !important;
    padding: 0.9rem 1.5rem;
    border: none !important;
  }
  .ModalDialoge .ModalFooter-pay {
    flex-direction: column-reverse;
  }
  .ModalCancel {
    padding: 0.7rem 1.4rem;
  }
  .ModalDialoge .modalBody .ChargeDetails span {
    color: $black;
    font-size: 1.5rem;
    font-weight: bold;
  }
  .modal-content {
    padding-bottom: 2rem;
  }
  .offcanvas-menu {
    background-color: #51cf29;
  }
  .client_profile--submission {
    flex-direction: column-reverse;
  }
  .form-btns {
    flex-direction: column-reverse;
  }
  .client_profile--submission button {
    margin: 0px 0px;
  }
  .delete-btn {
    margin-top: 2rem;
    font-size: 15px;
  }
  .d-sm-none {
    display: none;
  }
  .client_profile--checkbox {
    margin-top: 0rem !important;
  }
  .sm-screen-mt {
    margin-top: 1rem !important;
  }

  .margin-sm {
    margin-bottom: 1rem;
  }
  .billingTable {
    white-space: nowrap !important;
  }
  .appointment-table {
    white-space: nowrap !important;
  }
  .form-setting {
    padding: 0;
  }
  .remove-address-btn {
    width: 100%;
    font-size: 12px !important;
    padding: 0.6rem 1rem;
    border-radius: 25px;
    background-color: #ffd5d4 !important;
    border: none !important;
    color: #555 !important;
    display: block !important;
  }
  .sm-secondary-btn {
    width: 100%;
    margin-left: 0 !important;
  }
  .sm-trash {
    margin-top: -4px;
    margin-right: 3px;
  }
  .client_profile--datePicker {
    display: block;
  }
  .client_profile--age {
    margin: 0px;
    float: right;
  }
  .request-btn::before {
    margin-right: 0.4rem !important;
  }
  .about-provider-details {
    display: grid;
  }
  .provider-details {
    margin-bottom: 1rem !important;
  }
  .provider-card-box {
    display: grid;
  }
  .payment-checkbox {
    display: grid !important;
    margin-top: 0.3rem;

    & div:last-child {
      @include breakpoint-down(xxSmall) {
        padding-left: 1rem;
      }
    }
  }
  .payment-box-footer {
    display: flex;
    flex-direction: column-reverse;
    float: left !important;
    width: 100%;
    .ModalAdd {
      width: 100%;
      margin-left: 0;
      padding: 0.7rem 2rem !important ;
    }
    .ModalCancel {
      width: fit-content;
      margin: auto;
      margin-top: 1rem;
      background-color: #d1d8e5 !important;
      color: #555 !important;
      border: none !important;
    }
  }
  .payment-detail-btn {
    display: flex;
    flex-direction: column-reverse;
    width: 100%;
    .reqbgColor {
      margin-left: 0;
      padding: 0.7rem 1rem;
      margin-bottom: 1rem;
    }
    .ModalCancel {
      width: fit-content;
      margin: auto;

      background-color: #d1d8e5 !important;
      color: #555 !important;
      border: none !important;
    }
  }
  .sm-text-center {
    text-align: center !important;
  }
  .uploader-image {
    width: 230px !important;
  }
  .client_profile {
    margin-top: 1rem;
  }
  .chat-box {
    background-color: #fff !important;

    border-radius: 0px !important;
    padding: 1rem 0rem !important;
  }
  .chat-box .chat-message {
    height: auto;
    margin-top: 0rem !important;
  }
  .delete-modal-body {
    padding: 1.4rem 1rem !important;
  }
  .sm-none-preview {
    display: none !important;
  }
  .lg-none-preview {
    display: block !important;
  }
  // .stripe-input{
  //   width: 50% !important;
  // }
  //////////////////////  Chatbox ////////////////////

  .chat-box {
    height: 80vh;
    margin: 1rem auto;
    .chat-message {
      .image-left-direction {
        margin-top: 0 !important;
        .chat-box-div {
          .avatar-image-chatbox {
            margin-top: 0.5rem;
            img {
              width: 25px;
              height: 25px;
            }
          }
          .receiver-message-box {
            padding: 0.5rem 0.35rem;
            font-size: 9px !important;
            .fs-11 {
              font-size: 9px !important;
              margin-bottom: 0 !important;
              p {
                margin-bottom: 0.2rem !important;
              }
            }
            small {
              display: flex;
              justify-content: flex-end;
              font-size: 9px;
            }
          }
        }
      }
      .image-right-direction {
        margin-top: 0.2rem !important;
        .chat-box-div {
          .avatar-image-chatbox {
            margin-top: 0.5rem;
            img {
              width: 25px;
              height: 25px;
            }
          }
          .sender-message-box {
            padding: 0.5rem 0.35rem;
            font-size: 9px !important;
            .fs-11 {
              font-size: 8px !important;
              margin-bottom: 0 !important;
              p {
                margin-bottom: 0.2rem !important;
              }
            }
            small {
              display: flex;
              justify-content: flex-start;
              font-size: 8px;
            }
          }
        }
      }
    }
    .editor {
      padding: 0.1rem 0rem !important;
      .rdw-editor-wrapper {
        .rdw-editor-main {
          height: 100px !important;
        }
      }
    }
    .alert-box-main {
      font-size: 9px;
      padding: 0 !important;
    }
    .alert-danger {
    }
  }
  /////////////////////////////Payment Modals

  .sm-column-reverse {
    display: flex;
    flex-direction: column-reverse;
    .sm-borders {
      border-bottom: 0.5px solid #999;
      border-top: 0.5px solid #999;
      padding: 1rem 0rem;
      .d-flex-sm {
        display: flex;
        align-items: center;
      }
      .edit-payment-sm {
        display: block;
        border: 1px solid #888;
        border-radius: 50%;
        padding: 0.4rem;
        margin-left: 10px;
        cursor: pointer;
      }
    }
    .padding-right-0 {
    }
    .ChargeDetails {
      margin-bottom: 2rem !important;
      // border-bottom: 1px solid #000;
      p {
        font-size: 13px;
      }
      span {
        font-size: 15px !important;
      }
      .sm-setup-invoice {
        margin-top: 0 !important;
      }
    }
  }
  .pdl-1 {
    padding: 0;
  }
  .saved_card_elements {
    position: relative;
    align-items: end;

    & svg {
      margin-left: 0.6rem;
    }

    &--card {
      display: block;
      .sm-d-flex {
        display: flex;
      }
    }
    &--sm-default-box {
      position: absolute;
      top: -4px;
      right: 6%;
    }

    &--radio {
      margin-top: 1rem !important;
    }

    &--cardExpiry {
      & input {
        @include breakpoint-down(xxSmall) {
        }
      }
    }
    &--default-badge {
      border-top-left-radius: 0 !important;
      border-top-right-radius: 0 !important;
      padding: 0.2rem 0.8rem;
      font-size: 10.5px;
    }

    &--cardNumber {
      padding-left: 0.7rem;
      margin-top: 0.5rem;
      & input {
        @include breakpoint-down(xxSmall) {
          font-size: 10px;
        }

        &:last-child {
        }
      }
    }
  }

  .payment-modal-footer {
    display: block;
    justify-content: flex-start !important;
    border: none !important;
    Button {
      margin-left: 0 !important;
    }
  }
  .secondary-btn {
    margin-left: 0 !important;
  }
}

//////////////////////////// Tablet Screen //////////////////
@media screen and (min-width: 421px) and (max-width: 767px) {
  .gender-mt {
    position: relative !important;
  }

  .fab-wrapper {
    display: block !important;
  }
  .remove-address-btn {
    display: none;
  }
  .billingEditBtn {
    width: 100% !important;
    margin-left: 0 !important;
  }
  .request-btn {
    width: 100% !important;
    margin-left: 0 !important;
    // display: none;
  }
  .billingCard .billingHeader .searchDiv {
    margin-top: 0 !important;
  }
  .request-btn-div {
    margin-top: 0 !important;
    margin-bottom: 1rem !important;
    display: none;
  }
  .TablePadding {
    margin-right: 0.9rem !important;
  }
  .tableSetting {
    padding-right: 15px !important;
  }
  .client_profile--submission {
    justify-content: space-between;
    margin-top: 1rem;
  }
  .delete-btn {
    width: 100%;
  }
  .cancel-btn {
    width: 100%;
  }
  .save-btn {
    width: 100%;
  }
  .billing-header-btn {
    display: block;
  }
}

@media only screen and (min-device-width: 200px) and (max-device-width: 500px) and (-webkit-min-device-pixel-ratio: 1) {
  .main-box-pagination {
    height: 8.2rem !important;
    position: relative !important;
  }
}

@media only screen and (min-device-width: 501px) and (max-device-width: 575px) and (-webkit-min-device-pixel-ratio: 1) {
  .main-box-pagination {
    height: 6.9rem !important;
    position: relative !important;
  }
}
@media only screen and (min-device-width: 576px) and (max-device-width: 800px) and (-webkit-min-device-pixel-ratio: 1) {
  .main-box-pagination {
    height: 9rem !important;
    position: relative !important;
  }
}
@media screen and (max-width: 980px) {
  .sm-screen-styling {
    display: block !important;
  }
}
.file-name {
  @include breakpoint-down(xxSmall) {
    max-width: 10rem;
  }
}
.add-card {
  @include breakpoint-down(large) {
    margin-left: 0;
  }
}
