.insuranceForm{
    padding: 1rem 1rem ;
}
.form-check{
    margin-top: 0.5rem !important;
}

.uploader-card{
    background-color: $white !important;
    border-radius: 25px !important;
    border-style: dotted !important;
    position: relative;
    
}
.uploader-bottom-div{
   background-color: darkgrey;
    padding: 0.5rem 1rem;
    width: fit-content;
    text-align: center;
    margin-top: -1.7px !important;
    margin: auto;
    align-items: center;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
}
