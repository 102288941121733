.pd {
  padding: $btn-sm-padding-without-icon;
}

.pd-s {
  padding: 0.4rem 1rem;
}
.button-color {
  color: #f99b40 ;
}
.button-green {
  background-color: $ethera-darkgreen !important;
  padding: $button-green-padding !important;
  border: none !important;
  position: relative;

  &:active {
    background-color: $ethera-darkgreen !important;
  }
  &:focus {
    background-color: $ethera-darkgreen !important;
  }

  @include breakpoint-down(xSmall) {
    width: 100%;
  }

  &::before {
    content: "+";
    position: absolute;
    left: 4px;
    top: 4px;
    background-color: #fff;
    color: $ethera-darkgreen;
    width: 1.5rem;
    height: 1.5rem;
    // z-index: 5;
    font-size: 1.5rem;
    font-weight: bold;
    line-height: 1;
    border-radius: 20px;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    -ms-border-radius: 20px;
    -o-border-radius: 20px;
  }
}
.button-primary {
  background-color: #f99b40 !important;
  padding: 8px 15px 8px 15px !important;
  border: none !important;
  position: relative;
  color: $white !important;

  &:active {
    background-color: #f99b40 !important;
    color: $white !important;
  }
  &:focus {
    background-color: #f99b40 !important;
    color: $white !important;
  }
  &:hover {
    box-shadow: 0 3px 9px -5px #82868b !important;
  }
}
.button-success {
  @include flex-center;
  background-color: $ethera-darkgreen !important;
  border: none !important;
  position: relative;

  &:active {
    background-color: $ethera-darkgreen !important;
  }
  &:focus {
    background-color: $ethera-darkgreen !important;
  }
}
