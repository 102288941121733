.ModalDialoge{
    margin:auto;
    margin-top: 12rem;
    justify-content: center;
    
    .ModalHeader{
        background-color: $white;
        padding-left: 2rem;
        font-weight: bold!important;
        display: flex!important;
        
    }
    .modal-title{
        display: contents;
    }
    .modalBody{
        padding: 1rem 2rem!important;
        .ChargeDetails{
            font-size: 11px;
            padding-top: 0.2rem;
            padding-left: 0.3rem;
        }
        .ModalLabel{
            padding-left: 1rem;
            padding-top: 0.8rem;
        }
        .ModalInput{
         border-radius: 25px;
         font-size: 10px;
        }
    }
    .ModalFooter{
        border: none;
        
        .ModalAdd{
            background-color: $ethera-ModalAdd-bgColor!important;
            padding: 0.59rem 1.8rem;
            border: none !important;
        }
    }
}
.modal-paynow{
    max-width: 47rem;
}
.payment-modal-footer {  
     display: none;
}
.edit-payment-sm{
    display: none;
}