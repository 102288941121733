//  ================================================================================================
//  ? TIP:  It is recommended to use this file for overriding bootstrap variables.
//  ================================================================================================
$brand-text: #a6a6a6 !default;
$ethera-selected: #e2dbc8 !default; // e2dbc8
$bg-ethera: #fefbf4 !default; //#fefbf4
$ethera-text-color: #783e34 !default; // #783e34
$etheraHeader: #f2f2f2 !default; // #f2f2f2
$ether-calendar-active: #45556f !default;
$ethera-gray: #9f9f9f !default;
$ethera-gray-dark: #9faaca !default;
$ethera-gray-light: #d0d8e5 !default;
$ethera-prevcard-bgColor: #e5e3d7 !default;
$ethera-nextcard-bgColor: #d1d8e5 !default;
$black: #000 !default;
$white: #fff !default;
$ethera-white: #fff !default;
$bg-dark: #283046 !default;
$bg-dark-blue: #10163a !default;
$ethera-darkgreen: #699577 !default;
$ethera-green: #a8b482 !default;
$ethera-billingTable-header: #f9f3e5 !default;
$ethera-ModalAdd-bgColor: #79a5ca !default;
$ethera-table-header: #343d55 !default;
$ethera-list-badge: #d1d8e5 !default;
$ethera-secondary-btn: #fff9ef !default;
$ethera-provider-card: #f5f5f5 !default;
$ehtera-provider-bg: #161d32 !default;
$ethera-billing-due-bgColor: #90a1c2 !default;
$ethera-billing-past-bgColor: #db8886 !default;
$ethera-cancel-btn: #d1d8e5 !default;
$ethera-brick: #dc8886 !default;
$ethera-income-head: #f5f5f5 !default;
$text-icon-border: #5a606a !default;
$ethera-project-head: #d1d8e5 !default;

$fab-close-line-length: 1.64rem;
$fab-close-line-thickness: 2px;
$fab-close-animation-time: .3s;
$fab-close-spins: 1;

// paddings
$button-green-padding: 8px 15px 8px 40px;
$btn-sm-padding-without-icon: 0.5rem 1.5rem;

// font-sizes
$font-size-x-small : 10px;
$font-size-small : 12px;
$font-size-s-med : 14px;
$font-size-med : 16px;
$font-size-l-med : 18px;
$font-size-large : 20px;
$font-size-x-large: 22px;
$font-size-xx-large: 24px;
$font-size-xxx-large: 26px;

// paddings
$selectorPaddings: 7px 10px;
$button-green-padding: 8px 15px 8px 40px;
$header-side-paddings: 0px 25px;
$tag-padding: 0.5rem 1rem;
$text-icon-padding: 0.4rem 1rem;
$btn-med-padding: 0.6rem 1.5rem;
$btn-sm-padding: 6px 15px;
$btn-padding: 6px 1.6rem;
$btn-sm-padding-without-icon: 0.5rem 1.5rem;

$margin-0: 2px !default;
$margin-1: 4px !default;
$margin-2: 8px !default;
$margin-3: 12px !default;
$margin-4: 16px !default;
$margin-5: 20px !default;
$margin-6: 24px !default;
$margin-7: 28px !default;
$margin-8: 32px !default;
$margin-9: 36px !default;
$margin-10: 40px !default;
$margin-11: 50px !default;

// font -sizes
$font-size-h1: 32px !default;
$font-size-h2: 28px !default;
$font-size-h3: 24px !default;
$font-size-h4: 20px !default;
$font-size-h5: 18px !default;
// $font-size-xs: 10px !default;
// $font-size-sm: 12px !default;
$font-size-md: 14px !default;
// $font-size-lg: 16px !default;
