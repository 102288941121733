// @import flex-start-center;
@import "../../utils/mixins";
.saved_card_elements {
  cursor: pointer;
  padding: 0.5rem 1rem;
  border-radius: 10px;
  margin-bottom: 5px;
  width: -webkit-fill-available;

  @include flex-between-center;

  & svg {
    margin-left: 5px;
    margin-right: 15px;
  }

  &--card {
    @include flex-start-center;
    .sm-d-flex {
      display: flex;
    }
  }

  &--radio {
    margin: 0 !important;
  }

  &--cardExpiry {
    width: fit-content;
    direction: rtl;
    & input {
      max-width: 50px !important;
      padding-left: 0 !important;
      padding-right: 0 !important;

      @include breakpoint-down(xxSmall) {
        width: 35px;
      }
    }
  }
  &--default-badge {
    white-space: nowrap;
    background-color: #e9e9e9 !important;
    border-radius: 5px;
    padding: 0.3rem 0.5rem;
    font-size: 7px;
    border: 0.5px solid #555;
    color: $black;
    // margin-left: 10px;
    // direction: rtl;
  }

  &--cardNumber {
    margin-bottom: 0 !important;
    & input {
      background-color: transparent !important;
      border: none !important;
      border-radius: 0 !important;
      color: #b5b5b5 !important;
      font-size: 14px;

      @include breakpoint-down(xxSmall) {
        font-size: 8px;
      }

      &:last-child {
        margin-right: 10px;
      }
    }
  }
}

.payment-trash-btn {
  background-color: $ethera-billing-past-bgColor;
  padding: 0.2rem 0.7rem;
  border-radius: 24px;
  margin-left: 10px;
  margin-bottom: 0.6rem;
  cursor: pointer;
}
.delete-modal-body {
  padding: 1.4rem 6rem !important;
  .payment-delete-btn {
    background-color: #db8886 !important;
    border: none;
    color: #803a39 !important;
    font-weight: bold;
  }
  .payment-cancel-btn {
    background-color: #dfdfdf !important;
    border: none;
    color: #000 !important;
  }
}
.sm-block-preview {
  display: none;
}
.lg-none-preview {
  display: none !important;
}
