::-webkit-scrollbar {
  width: 5px;
  height: 4px;
}

::-webkit-scrollbar-track {
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: #6580af;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}
.invalid {
  border: 1px solid red !important;
}
.error-class {
  color: red; /* Change text color to red */
  font-size: 12px; /* Adjust font size */
  /* Add more styles as needed */
}
.card-element-container {
  padding: .6rem 1rem;
  border-radius: 4px;
  min-height: 16px;
  position: relative;
  padding: 0.571rem 1rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.45;
  color: #6E6B7B;
  border: 1px solid #D8D6DE;
  border-radius: 0.357rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

}
// logo
.bookmark-wrapper {
  @include breakpoint-up(xLarge) {
    & img {
      margin-left: 15px !important;
    }
  }
}

.r-10 {
  border-radius: 10px;
}

.mb-5px {
  margin-bottom: 5px !important;
}

.mt-5px {
  margin-top: 5px;
}

.width-cvc {
  width: 40%;

  @include breakpoint-up(small) {
    width: 30%;
  }
}

.pointer {
  cursor: pointer;
}

.fs-22 {
  font-size: 22px;
}

.logo-text {
  color: $bg-dark;
  font-size: 20px;
  margin-left: 5px !important;
}

.document-URL:hover {
  color: $black !important;
}
.ethera-modal-top-background {
  background-color: $ethera-gray-dark !important;
  border-radius: 0 !important;
}
.padding-29 {
  padding-left: 29px !important;
  margin-top: -7px !important;
}
.text-ethera-dark {
  color: $ethera-text-color !important;
}

.background-fff {
  background-color: #fff !important;
}

.dark-logo-text {
  color: #fefefe !important;
}

.layout-light {
  background-color: $ethera-selected;
  color: $black;
}

.layout-dark {
  background-color: $bg-dark;
  color: $white;
}

.relative {
  position: relative !important;
}

.absolute {
  position: absolute !important;
}

.calendarIcon {
  background: $ether-calendar-active;
  display: inline-block;
  padding: 7px;
  color: #fff;
  border-radius: 50%;
  text-align: center;
}
.search {
  right: 100px;
  width: 300px;
}

.AddButton {
  right: 20px;
  & button {
    background: $ethera-green;
    display: inline-block;
    padding: 10px;
    color: #fff;
  }
}

.fc-addAppointment-button {
  position: relative;
  background: $ethera-green !important;
  border: none !important;
  border-radius: 50px !important;
  -webkit-border-radius: 50px !important;
  -moz-border-radius: 50px !important;
  -ms-border-radius: 50px !important;
  -o-border-radius: 50px !important;
  padding: 0.3rem 2rem 0.3rem 3rem !important;
  display: inline-block;
  color: #fff;

  &::before {
    content: '+';
    font-size: 1.5rem;
    font-weight: bold;
    line-height: 1;
    position: absolute;
    top: 1;
    left: 4px;
    width: 1.5rem;
    height: 1.5rem;
    text-align: center;
    color: $ethera-green;
    background-color: $white !important;
    z-index: 5;
    border-radius: 100px;
    -webkit-border-radius: 100px;
    -moz-border-radius: 100px;
    -ms-border-radius: 100px;
    -o-border-radius: 100px;
  }
}
button[disabled] {
  cursor: not-allowed !important;
  pointer-events: visible !important;
  opacity: 0.5 !important;
}
.fc-calendarIcon-button {
  color: $ethera-green !important;
}

.fc-searchEvent-button {
  background: transparent !important;
  &:hover {
    background: transparent !important;
  }
}

.venueSelectBlack {
  color: $white !important;
}

.venueSelect {
  margin-right: 20px !important;
  background-color: transparent !important;
  max-width: 280px;
  min-width: 220px;
  padding: 0.2rem 1rem 0.2rem 3rem !important;
  border: 1px solid $ethera-gray !important;
  border-radius: 20px !important;
  -webkit-border-radius: 20px !important;
  -moz-border-radius: 20px !important;
  -ms-border-radius: 20px !important;
  -o-border-radius: 20px !important;
  cursor: pointer;

  & select {
    appearance: none !important;
  }
}

.selectFormGroup {
  margin: 0 !important;
}

.datePicker-icon {
  background-color: $ether-calendar-active !important;
  display: inline-block !important;
  padding: 5px !important;
  border-radius: 50% !important;
  text-align: center !important;
  top: 25px !important;
  left: 10px;
}

.datePicker {
  width: 5px !important;
  padding: 0.271rem 1rem !important;
  top: 25px !important;
  left: 10px;
  border: none !important;
  z-index: 3 !important;
}

.btn-complete {
  background-color: $ethera-green !important;
  border: none !important;
  font-weight: 700 !important;
  color: rgba(0, 0, 0, 0.6) !important;
  font-size: 18px !important;
}

.modal-body {
  padding: 0 !important;
}

.modal-heading {
  background-color: $ethera-gray-light;
  display: inline-block;
  padding: 0.571rem 1rem;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.modal-paddings {
  padding: 0.8rem 1.4rem;
}

.select__control {
  padding-left: 0.571rem;
  padding-right: 0.571rem;
  border: 1px solid $ethera-gray !important;
  border-radius: 15px !important;
  -webkit-border-radius: 15px !important;
  -moz-border-radius: 15px !important;
  -ms-border-radius: 15px !important;
  -o-border-radius: 15px !important;
}

.input.form-control {
  background-color: transparent !important;
}

.mb-0_5 {
  margin-bottom: 0.5rem;
}

.select-w-70 {
  width: 70% !important;
}

.select-w-35 {
  width: 35% !important;
}

.radius-15 {
  border-radius: 15px !important;
  -webkit-border-radius: 15px !important;
  -moz-border-radius: 15px !important;
  -ms-border-radius: 15px !important;
  -o-border-radius: 15px !important;
}

.mb-0-formGroup {
  margin-bottom: 0 !important;
}

.light-colors {
  color: #fefefe !important;
  border-color: #fefefe !important;
  background: transparent !important;
}

.time-div {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
}

.Popover-custom-header {
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
  justify-content: space-between !important;
}

// **************************************************************************************************************************
// **************************************************************************************************************************

// Custom Add button Secondary And Success
.request-btn {
  background-color: $ethera-darkgreen !important;
  font-size: 10px;
  font-weight: bold;
  margin-left: 0.2rem;
  white-space: nowrap;
  padding: 7px 1rem !important;
  border-width: 0;
  &::before {
    content: '+';
    margin-left: -9px;
    color: $ethera-darkgreen;
    border-radius: 50%;
    margin-right: 0.5rem;
    left: -2px !important;
    font-weight: bold;
    position: relative;
    padding: 2px 6px;
    background-color: $white !important;
  }
}
.request-btn:focus,
.request-btn:active,
.request-btn.active {
  background-color: $ethera-darkgreen !important;
}
.request-btn-sm {
  background-color: #a9b583 !important;
  font-size: 10px;
  font-weight: bold;
  border-radius: 6px !important;
  margin-left: 0.2rem;
  white-space: nowrap;
  border: none;
  color: $black !important;
}
.request-btn-sm:focus,
.request-btn-sm:active,
.request-btn-sm.active {
  background-color: #a9b583 !important;
}

.secondary-btn {
  background-color: $ethera-secondary-btn !important;
  font-size: $font-size-small;
  font-weight: 400;
  border: 1px solid rgba(#924511, 0.6) !important;
  margin-left: 1rem;
  margin-top: 1rem;
  color: $black !important;
  white-space: nowrap;
  width: fit-content;
  &::before {
    content: '+';
    margin-left: -9px;
    font-size: 12px;
    border-radius: 50%;
    margin-right: 0.1rem;
    left: 0 !important;
    font-weight: bold;
    position: relative;
    padding: 0.2rem 0.4rem;
  }
}
.secondary-btn:focus,
.secondary-btn:active,
.secondary-btn.active {
  background-color: $ethera-secondary-btn !important;
}

// Font Sizes
.card-title {
  font-size: $font-size-l-med !important;
  font-weight: bold !important;
  @include breakpoint-up(xxSmall) {
    font-size: $font-size-xxx-large !important;
  }
}
.fs-12 {
  font-size: 12px !important;
}
.font-size-11 {
  font-size: 11px !important;
}
.fs-11 {
  font-size: 11px !important;
}
.fs-10 {
  font-size: 10px !important;
}
.fs-8 {
  font-size: 8px !important;
}
///  Font Weight
.fw-800 {
  font-weight: 800 !important;
}
.fw-bold {
  font-weight: bold !important;
}

// Margins
.margin-bottom-0 {
  margin-bottom: 0;
}
.mb-15 {
  margin-bottom: 15px !important;
}
.mt-32 {
  margin-top: 1.7rem !important;
}
.ml--7 {
  margin-left: 7px;
}
.mt---1 {
  margin-top: -1px !important;
}
.ml-5px {
  margin-left: 5px;
}
.ml-10px {
  margin-left: 10px;
}
.mr-5px {
  margin-right: 5px;
}
.ml-1 {
  margin-left: 1rem;
}

// Paddings
.pl-5px {
  padding-left: 5px;
}

.pl-1 {
  padding-left: 14px;
}
.pl-2 {
  padding-left: 20px;
}
.padding-left {
  padding-left: 0;
}
.padding-left-input {
  padding-left: 1.3rem;
  font-size: 10px;
}
.padding-billing-badge {
  padding: 0.5rem 1.45rem !important;
}
.tableSetting {
  padding-right: 34px;
  padding-left: 0 !important;
}
.appointment-tr td {
  padding: 1.2rem 1.3rem;
}
.padding-right-0 {
  padding-right: 0 !important;
}
[data-column-id='Date'].rdt_TableCol {
  padding-left: 1rem !important;
}
// BackGrounds Colors
.due-bdColor {
  background-color: $ethera-billing-due-bgColor !important;
  color: $black;
}
.pastDue-bgColor {
  background-color: $ethera-billing-past-bgColor !important;
  color: $black;
}
.cardBodybgColor {
  background-color: $white;
  border-color: #f1f0ed;
}
.reqbgColor {
  background-color: $ethera-darkgreen !important;
  border: none !important ;
}
.PaidBillingColor {
  background: rgba(#28c76f, 0.5) !important;
  color: #28c76f !important;
  border: 1px solid #28c76f !important;
}
.dueBillingColor {
  background: rgba(#90a1c2, 0.5) !important;
  color: #90a1c2 !important;
  border: 1px solid #90a1c2 !important;
}
.unPaidBillingColor {
  background: rgba(#ea5455, 0.5) !important;
  color: #ea5455 !important;
  border: 1px solid #ea5455 !important;
}
//Focus And Active

.reqbgColor:focus,
.reqbgColor:active,
.reqbgColor.active {
  background-color: $ethera-darkgreen !important;
  border: none !important ;
}
.insurance-card-bgColor:focus,
.insurance-card-bgColor:active,
.insurance-card-bgColor.active {
  background-color: $ethera-list-badge !important;
}

/////////////////////
.view-check-document {
  background-color: #a9b583;
  border-radius: 50%;
  padding: 0.15rem;
  margin-left: 0.4rem;
}
.check-icon {
  font-weight: 800;
  font-size: 19px;
}
.side-Box {
  background-color: $ethera-list-badge;
  padding: 0.5rem 1.5rem;
  width: fit-content;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  font-weight: 800;
  color: $black;
  margin-left: -22px;
}

.cardBgColor {
  background-color: $bg-ethera;
}
.card-body-bg-white {
  background-color: $white !important;
}
.phone-checks {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.trash-btn {
  border-radius: 50%;
  padding: 0.4rem 0.7rem;
  cursor: pointer;
}
.portal-col-pr-0 {
  padding-right: 0 !important;
}
.client-portal-btn {
  width: 100%;
  margin-top: 2rem !important;
  margin-bottom: 0.8rem;
  white-space: nowrap;
}

.email-checks {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin: auto;
}
.div-height {
  height: 5rem !important;
}
.checkBoxes {
  display: inline-grid;
  padding-left: 2rem;
}
.started-cardBody {
  height: 80vh !important;
}
.border-none {
  border: none !important;
}
.service-input {
  background-color: $ethera-provider-card !important;
}
.form-check-inline {
  margin-right: 0.4rem !important;
}
.authorization-checkbox {
  margin-top: 2.5rem;
}
.padding-billing-btn {
  padding: 0.45rem 2rem;
}
.float-right {
  float: right !important;
}
.input-style {
  border-radius: 25px !important;
}
.trash-btn-hover {
  cursor: pointer;
}
.checkbox-top {
  margin-top: 0.6rem !important;
}
.PhoneInputInput {
  border: none !important;
}
.bgyellowish {
  background-color: $bg-ethera;
}
.success-Color {
  color: $ethera-darkgreen !important;
}
.List-padding-left {
  padding-left: 3rem;
}
.text-color {
  color: $ethera-text-color !important;
}
.form-group--height {
  height: 5rem;
}
.card {
  box-shadow: none !important;
}
.set-tableData-width {
  width: 45% !important;
}
.insurance-card-header {
  font-size: 10px !important;
}
.email-label {
  padding-right: 0.2rem !important;
}
.PhoneInputInput:focus-visible {
  border: none !important;
  outline: none;
}
.phone-input-country {
  border-radius: 25px !important;
  border: none;
  padding: 0.6rem 0.6rem 0.6rem 2.4rem;
}
.phone-input-country:focus {
  box-shadow: 0 3px 10px 0 rgb(0 0 0 / 10%) !important;
  border: none !important;
}
.radio-setting {
  display: inline-grid;
  padding: 10px 0px 0px 12px;
}
.seletDiv {
  padding-left: 2.3rem;
}
.select {
  width: 7rem;
  border-radius: 29px;
  font-size: 12px;
  margin-left: 10px;
}
.labelsetting {
  font-size: 12px;
  font-weight: bold;
}

.list-group-document {
  font-size: small;
  border-right: none;
  border-left: none;
}
body {
  & div {
    & .wrapper {
      background-color: $bg-ethera;
    }
  }
}
.bookmark-app {
  list-style: none;
  padding: 0;
  margin-bottom: 0;
}
.badge-billing {
  font-size: 10px !important ;
  padding: 0.5rem 0.6rem;
}
.insurance-card-bgColor {
  background-color: $ethera-list-badge !important;
  border: none;
  color: #000 !important;
}

.year-badge {
  background-color: $ethera-list-badge !important;
  border: none;
  color: #555;
  border-radius: 20px;
  padding: 0.5rem 1rem;
  margin-top: 2rem;
}
.form-check .form-check-input[type='checkbox'] {
  float: left !important;
  margin-left: -1.786rem !important;
  width: 1.1rem !important;
  height: 1.1rem !important;
  margin-top: 4px !important;
}

.service-code-box {
  background: $ethera-nextcard-bgColor !important;
  width: fit-content;
  padding: 9px 18px;
  border-top-right-radius: 9px;
  border-top-left-radius: 9px;
}

.billing-header-btn {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding-right: 0.5rem;
}
.btn-td {
  z-index: 999 !important;
}
.stripe-input {
  font-size: 11px;
  padding: 0.5rem;
  border-radius: 25px;
}

.avatar-image-chatbox {
  border-radius: 50%;
}
.folderPlus {
  margin-top: -3px;
}

/////////////   Profile Form

.radius-25 {
  border-radius: 25px !important;
  -webkit-border-radius: 25px;
  -moz-border-radius: 25px;
  -ms-border-radius: 25px;
  -o-border-radius: 25px;
}
.black {
  color: $black;
}
.text-align-right {
  text-align: right !important;
}
.btn-green-large {
  background-color: #6b9879 !important;
  border: none !important;
  text-align: center !important;
}
.btn-large-transparent {
  padding: 0.5rem 1.5rem !important;
  background-color: transparent !important;
  border: none !important;
}
.client_profile--checkbox {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: flex-end;
}
.imageUploader {
  cursor: pointer;
  padding-top: 2rem !important;
  border: 1px dashed rgba(0, 0, 0, 0.6) !important;
  border-radius: 15px;
  margin: 0 20px 20px 20px;
  @include breakpoint-down(xxxSmall) {
    margin: 5px;
  }
}
.start-btn {
  color: #fff !important;
}
.insurance-cancel-btn {
  background-color: #d8d8d8 !important;
  border: none !important;
  color: $black !important;
  font-size: 10px !important;
}
.bg-ethera {
  background-color: $bg-ethera;
}

.paymentInput {
  border: none !important;
  margin-left: 1rem;
  font-size: 18px;
}
.paymentInput:focus {
  box-shadow: none !important;
}
// .required-dot {
//   height: 5px;
//   width: 5px;
//   border-radius: 10px;
//   -webkit-border-radius: 10px;
//   -moz-border-radius: 10px;
//   -ms-border-radius: 10px;
//   -o-border-radius: 10px;
//   background-color: red;
// }
.required-dot::after {
  content: '*';
  height: 12px;
  width: 5px;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  color: red;
  display: block;
}
.m-r-1 {
  margin-right: 05px !important;
}

.card-body-styling {
  background-color: $white !important;
}

.uploader-lower-box {
  background: $ethera-nextcard-bgColor;
  padding: 0.5rem 1rem;
  width: fit-content;

  margin: auto;
  border-bottom-left-radius: 7px;
  border-bottom-right-radius: 7px;
  margin-top: -1px;
}
.modal-backdrop {
  opacity: 0.8 !important;
  background-color: #283046;
}

.fab-wrapper {
  position: fixed;
  bottom: 8rem;
  right: 3rem;
}
.fab-checkbox {
  display: none;
}
.fab {
  position: absolute;
  bottom: -1rem;
  right: -1rem;
  padding: 0.4rem;
  background: #a9b583;
  border-radius: 14px;
  transition: all 0.3s ease;
  z-index: 1;
}

@keyframes blink {
  50% {
    opacity: 0.25;
  }
}
.login-card-body {
  background-color: $white !important;
}
.payment-modal {
  margin-top: 5rem !important;
}
.marginLeftAuto {
  margin-left: auto;
}
.attachLink {
  cursor: pointer;
}
.fab-wheel {
  position: absolute;
  bottom: 0;
  right: 0;
  height: 5.5rem;
  transition: all 0.3s ease;
  transform-origin: bottom right;
  transform: scale(0);
}
.fab-checkbox:checked ~ .fab-wheel {
  transform: scale(1);
}
.plus-sm {
  margin-right: 3px;
  font-weight: bold;
}

.rdw-option-wrapper {
  background-color: transparent !important;
  border: none !important;
  border-radius: 0 !important;
}
.imageUploader-lowerBtn {
  height: 15rem;
  cursor: pointer;
  padding-top: 2rem !important;
  border: 1px dashed rgba(0, 0, 0, 0.6) !important;
  border-radius: 15px;
}
.gender-mt {
  margin-top: -10px !important;
  position: absolute;
}

.pointer {
  cursor: pointer !important;
}

.sm-none {
  @include breakpoint-down(xSmall) {
    display: none !important;
  }
}

.modal-delete {
  @include breakpoint-up(med) {
    min-width: 600px !important;
  }
}
.link {
  color: #3f71c4 !important;
}

.pl-28px {
  padding-left: 28px;
}
.mr-19 {
  margin-top: -19px !important;
  margin-left: 0.6rem !important;
}
.minHeightLoginInputs {
  min-height: 5.9rem !important;
}
.minHeightInput {
  min-height: 5.9rem;
}

.main-box-pagination-bg-color {
  background: $bg-ethera !important;
}
.main-box-pagination {
  height: 4rem !important;
  position: relative !important;
}

.pagination-position-fixed {
  position: fixed;
  bottom: -1px;
  width: -webkit-fill-available;
  background-color: #fff;
}
.rdt_TableRow {
  background-color: #EEEEEE !important;
}
.rdt_TableHeadRow {
  background-color: #EEEEEE !important;
}
.rdt_TableRow:hover {
  background-color: #EEEEEE !important;
  border-bottom-color: #EEEEEE !important;
  outline-color: #EEEEEE !important;

}
.rdt_TableHeadRow:hover {
  background-color: #EEEEEE !important;
}
.main-box-pagination-bg-color {
  background: $bg-ethera !important;
}
.invoice-services-table tr th,
.invoice-services-table tr td {
  padding-left: 0;
  padding-right: 0;
}
.object-fit-cover {
  object-fit: cover !important;
}
.mx-wd-130px {
  max-width: 130px;
}
.pl-10px {
  padding-left: 10px;
}
.plr-0 div div {
  // padding-left: 0.3rem !important;
  // padding-right:0rem !important;
}
.sender-loading {
  width: 10px;
  height: 10px;
}
.add-card-spinner-size {
  width: 12.3px !important;
  height: 12px !important;
}
.spinner-size-update {
  width: 12.43px;
  height: 12.7px;
}
.spinner-size-same {
  width: 12.38px;
  height: 12.4px;
}
.spinner-size-paynow {
  width: 12.38px;
  height: 12.4px;
}
.edit-notes-bg-color {
  background-color: #fff !important;
  color: #000 !important;
}
.text-ellipsis {
  max-width: 350px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.hover-black:hover {
  color: black !important;
}
.pl-05px {
  padding-left: 05px;
}
.checkbox-create-password {
  display: flex;

  flex-direction: row-reverse;
  justify-content: left;
}
.uploader-bg-color {
  background-color: transparent !important;
}
.file-name {
  width: 20rem;
  min-height: 1rem;
  font-weight: 600;
  flex-grow: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.paid-btn-padding {
  padding-left: 28px !important;
  padding-right: 28px !important;
}
.width-title {
  width: 70% !important;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  @include breakpoint-down(xxxxLarge) {
    width: 34rem !important;
  }
}
.check-in {
  margin: 0 10px;
}
.ModalCancel {
  background-color: #d1d8e5 !important;
  color: #555 !important;
  border: none !important;
}
.radio-setting {
  display: flex;
}
.button-danger {
  margin-left: 10px;
  @include flex-center;
  background-color: #ea5455 !important;
  border: none !important;
  position: relative;

  &:active {
    background-color: #ea5455 !important;
  }
  &:focus {
    background-color: #ea5455 !important;
  }

  @include breakpoint-down(med) {
    margin-bottom: 1rem;
  }
}

.button-cancel {
  @include flex-center;
  background-color: #d1d8e5 !important;
  border: none !important;
  color: rgba($bg-dark, 0.6);
  position: relative;

  &:active {
    background-color: #d1d8e5 !important;
  }
  &:focus {
    background-color: #d1d8e5 !important;
  }
}
.delete-spinner-size {
  width: 12.5px;
  height: 12.5px;
}
.check-in-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.add-more {
  background-color: #fff9f0 !important;
  border: 1px solid rgba(#783e34, 0.5) !important;
  color: $gray-800 !important;
  padding: 0.63rem 1.8rem !important;
  &:active {
    background-color: #fff9f0 !important;
  }
  &:focus {
    background-color: #fff9f0 !important;
  }
  & span {
    white-space: nowrap;
  }
}
.line-height-2 {
  line-height: 1.8rem !important;
  font-family: 'sofia-pro', Sans-serif !important;
  font-size: 16px !important;
  font-weight: 400;
}
.privacy-policy-ol-list {
  padding-left: 1rem;
}
.ul-points {
  list-style: none;
  font-family: 'sofia-pro', Sans-serif;
}

/* Use ::before to create a pseudo-element for the bullet */
.ul-points li::before {
  content: '';
  display: inline-block;
  width: 6px; /* Adjust the width of the bullet */
  height: 6px; /* Adjust the height of the bullet */
  background-color: black; /* Fill the bullet with the desired color */
  border-radius: 50%; /* Make the bullet circular (for a filled circle) */
  margin-right: 10px; /* Optional: Add some space between the bullet and the text */
}
.privacy-policy-body {
  background-color: #fefbf4;
  @include breakpoint-down(xSmall) {
    padding: 0;
  }
}
.privacy-policy-pd {
  padding: 3rem;
  @include breakpoint-down(xSmall) {
    padding: 1rem;
  }
}
.emailHover:hover {
  color: #336;
  text-decoration: underline;
  transition: 0.3s ease;
}
.underline {
  text-decoration: underline !important;
}
.privacy-policy-footer ul li {
  background-color: #6c8861;
  color: $white;
  padding: 10px 10px !important;
  border-radius: 5px !important;
}
.privacy-policy-footer ul .facebook-pd {
  padding: 10px 14px !important;
}
.horizantel-row-privacy {
  background-color: lightgray !important;
}

.privacy-policy-footer-nav {
  display: flex;
  justify-content: space-between;
  margin-top: 1.5rem;
  align-items: center;
  @include breakpoint-down(xSmall) {
    display: block;
    justify-content: center;
    text-align: center;
  }
}
@include breakpoint-down(xSmall) {
  .privacy-policy-footer ul {
    justify-content: center;
    padding-left: 0 !important;
  }
}
.card {
  background-color: #fff;
  margin-bottom: 2rem;
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
}
.modal-content {
  background-color: #fff;
  background-clip: padding-box;
  border: 0 solid rgba(34, 41, 47, 0.2);
  border-radius: 0.357rem;
}
.table {
  --bs-body-bg: transparent;
}
.list-group,
.popover,
.dropdown-menu {
  --bs-body-bg: #fff !important;
}
.align-item-center {
  align-items: center;
}
.telehealth-link {
  color: #3f71c4;
  text-decoration: underline;
}
.telehealth-link:hover {
  text-decoration: underline;
}
.animate-it{
  display: inline-block;
    animation: move 4s ease-in-out infinite;
    position: relative;
    padding-inline: 1rem;

@keyframes move {
    0% { transform: translate(0, 0); left: 0%; }
    75%, 100% { transform: translate(-100%, 0); left: 100%; }
}
}