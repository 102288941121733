.TablePadding {
  margin-right: 3rem;
}
.tableHaeding {
  background-color: $ethera-billingTable-header;
  padding: 1rem 2rem;
}
.billingTable {
  padding-left: 0% !important;
}
.billingTBody {
  background-color: $white;
}
.tableRow {
  cursor: pointer;
}
.BillingPayBtn {
  background-color: $ethera-darkgreen !important;
}
.BillingPayBtn:active {
  background-color: $ethera-darkgreen !important;
}
.Billing-Post-PayBtn {
  background-color: #000 !important;
}

.form-select:focus {
  border-color: $ethera-text-color;
  box-shadow: 0 3px 10px 0 rgb(0 0 0 / 20%);
}
.hide-header {
  div {
    .rdt_Table {
      .rdt_TableHead {
        display: none;
      }
    }
  }
}
.amex-card {
  margin-left: 7px;
}
@media screen and (max-width: 425px) {
  .TablePadding {
    margin-right: 0.6rem;
  }
  .billingCard .billingHeader .billingBtnDiv .BillingBtn {
    margin-left: 1rem;
    font-size: 6px;
  }
  .billingCard .billingHeader .billingBtnDiv .billingEditBtn {
    margin-left: 0rem;
    font-size: 6px;
  }
  .billingCard .billingHeader .billingBtnDiv {
    margin: 0rem 0rem 1rem 0rem;
  }
  .amex-card {
    margin-left: 10px;
    margin-top: -12px;
  }
}
