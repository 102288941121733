@import "../../utils/mixins";
.EditClient {
  background-color: $ethera-gray-light;
  border: apx solid rgba(0, 0, 0, 0.7);
  display: inline-block;
  padding: 7px 20px;
  border-radius: 40px;
  -webkit-border-radius: 40px;
  -moz-border-radius: 40px;
  -ms-border-radius: 40px;
  -o-border-radius: 40px;

  & span {
    font-size: 24px;
    font-weight: bold;
    color: $bg-dark;
  }
}

.client-tabs {
  background-color: $bg-ethera;
  // padding: $header-side-paddings;

  & span {
    background: transparent !important;
    padding: 0px 15px 10px 15px;
    font-size: 14px;
    cursor: pointer;

    &:first-child {
      margin-right: 20px;
    }
  }

  &__selected {
    font-weight: bold;
    border-bottom: 3px solid $ethera-text-color !important;
  }
}

.add_client_header {
  @include flex-start-center;
  &--status {
    & span {
      margin-right: 10px;
    }

    &:last-child {
      margin-left: 35px;
    }
  }
  @include breakpoint-down(med) {
    @include flex-column;
    align-items: flex-start;
  }
}

.client_profile {
  padding: 0 2rem;

  @include breakpoint-up(xLarge) {
    max-width: 80% !important;
  }

  &--sub {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: column;
    width: 100%;

    & button {
      width: 100%;
      margin-bottom: 5px;
    }

    @include breakpoint-up(xxSmall) {
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;

      & button:first-child {
        margin-right: 1rem;
      }

      width: fit-content;
      float: right;

      & button {
        width: fit-content;
        margin-bottom: 0;
      }
    }
  }

  &--checkbox {
    @include flex-row-reverse-end-center;
    cursor: pointer;
    margin-top: 1rem;

    & input {
      cursor: pointer;
    }

    & label {
      cursor: pointer;
      @include breakpoint-up(xxSmall) {
        white-space: nowrap;
      }
    }

    & div {
      margin-bottom: 0 !important;
    }
  }

  &--radiosAndChecks {
    @include flex-start-center;

    & strong {
      white-space: nowrap;
      font-size: 14px;
    }

    @include breakpoint-down(xSmall) {
      @include flex-column;
      align-items: flex-start !important;
    }

    & input {
      margin-left: 20px;
    }
  }

  &--tags {
    display: inline-block;
    background-color: $ethera-list-badge;
    margin: 0.5rem 0;
    padding: 0.7rem 2rem;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;

    & span {
      margin-left: 10px;
      font-size: 12px;
      font-weight: bold;
      color: $gray-800;
    }
  }

  &--addressTag {
    @include flex-start-center;

    & p {
      margin-bottom: 0;
      font-size: 12px;
      margin-left: 20px;
    }

    @include breakpoint-down(large) {
      flex-direction: column;
      align-items: flex-start;

      & p {
        margin-left: 2rem;
        margin-bottom: 1rem;
      }
    }
  }

  &--datePicker {
    @include flex-start-center;
    @include flex-row;

    @include breakpoint-down(med) {
    }
  }
  &--age {
    display: inline-block;
    background-color: $ethera-list-badge;
    padding: 0.4rem 1rem;
    border-radius: 20px;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    -ms-border-radius: 20px;
    -o-border-radius: 20px;
    margin-top: 1rem;
    margin-left: 20px;
    white-space: nowrap;
    x & span {
      white-space: nowrap;
    }
  }

  &--doubleCol {
    display: flex;
    flex-wrap: wrap;

    &__50 {
      min-width: 48%;

      @include breakpoint-down(xSmall) {
        min-width: 100%;
      }
    }

    &__20 {
      min-width: 20%;

      @include breakpoint-down(xSmall) {
        min-width: 100%;
      }
    }
  }

  &--billingType {
    & p {
      margin: 0;
    }
    & span {
      font-size: 10px;
    }
  }

  &--insuranceInformation {
    display: flex;

    @include breakpoint-down(med) {
      @include flex-column;
    }
  }

  &--clientDefaultService {
    background-color: $ethera-provider-card;
    position: relative;
    margin-top: 4rem;
    width: 95%;
    padding: 1.5rem;
    margin-left: 1rem;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;

    border-top-left-radius: 0 !important;

    &__head {
      display: inline-block;
      padding: 0.5rem 2rem;
      background-color: $ethera-gray-dark;
      border-top-right-radius: 10px;
      border-top-left-radius: 10px;
      position: absolute;
      left: 0;
      top: -33px;
    }
  }

  &--submission {
    @include flex-start-center;

    @include breakpoint-up(med) {
      @include flex-center;
    }

    & button {
      margin: 0px 10px;
    }
  }
}
.new_invoice_form {
  & strong {
    color: #495873;
  }
  @include breakpoint-up(xxxSmall) {
    padding: 1rem 2rem;
  }
  &--heading {
    font-size: 22px;
    font-weight: bold;
    color: #495873;
  }

  &--flatPickr {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    & p:last-child {
      margin-left: 10rem;
    }
  }

  &--total {
    display: flex;
    flex-direction: column;
    margin-right: 3rem;

    & div {
      @include flex-between-center;

      @include breakpoint-up(xxxSmall) {
        // margin-left: 30px;
        // margin-right: 30px;
      }
    }

    & span {
      font-size: 26px;
      font-weight: bold;
    }
  }
}
.delete-btn {
  background-color: $ethera-billing-past-bgColor !important;
  border: none !important;
  color: $black !important;
}
.delete-btn:focus,
.delete-btn:active,
.delete-btn.active {
  background-color: $ethera-billing-past-bgColor !important;
}
.trash-icon {
  margin-top: -3px !important;
}

.cancel-btn {
  background-color: $ethera-cancel-btn !important;
  border: none;
  font-weight: 400;
  color: #8f8f8f !important;
}
.cancel-btn:focus,
.cancel-btn:active,
.cancel-btn.active {
  background-color: $ethera-cancel-btn !important;
}
.save-btn {
  padding: 5.5px 25px;
}
.save-btn:focus,
.save-btn:active,
.save-btn.active {
  background-color: $ethera-darkgreen !important;
}

.file-preview {
  &--other {
    display: flex;
    align-items: center;
  }
}
.col-posiiton {
  position: relative;
  .phone-icon {
    position: absolute;
    top: 30px;
    left: 28px;
  }
}
.phone-pl {
  padding-left: 2.5rem !important;
}
.whiteSpace {
  white-space: nowrap !important;
}