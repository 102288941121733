@import '../../utils/mixins'; 

.completed-document {
    background-color: $ethera-list-badge;
    padding: 0.5rem 1.6rem;
    width: fit-content;
    border-bottom-right-radius: 12px;
    border-top-right-radius: 12px;
    margin: 1rem 0rem;
}
.page-header {
    // @include flex-center-start;
    @include flex-column;
  
    &--title {
      margin-bottom: 1rem;
      &__leftArrow {
        color: $ethera-text-color;
        cursor: pointer;
        transition: transform 250ms;
  
        &:hover {
          transform: translateX(-5px);
        }
      }
    }
  
    &--buttons_right {
      width: 100%;
  
      & button {
        width: 100%;
      }
  
      @include breakpoint-up(xSmall) {
        @include flex-center-start;
        margin-top: 0;
        justify-content: flex-end;
        width: fit-content;
  
        & select {
          min-width: 180px;
        }
  
        & button {
          width: fit-content;
          margin-left: 1rem;
        }
      }
    }
  
    &--selects {
      @include flex-end-center;
  
      & div {
        margin-left: 5px;
        margin-right: 5px;
      }
    }
  
    &--export {
      width: 100%;
      margin-top: 1rem;
  
      & button {
        width: 100%;
      }
  
      @include breakpoint-up(xSmall) {
        width: fit-content;
        margin-top: 0;
      }
    }
  
    &--bottom_date {
      width: 100%;
      padding-right: 5px;
  
      @include breakpoint-up(xSmall) {
        width: fit-content;
        margin-left: 1rem;
        margin-right: 1rem;
        padding-right: 2rem;
      }
  
      @include breakpoint-up(small) {
        padding-right: 3rem;
      }
    }
  
    @include breakpoint-up(xSmall) {
      @include flex-row;
      align-items: center;
      // justify-content: space-between;
    }
  }
  
  .list_overview {
    
  
    &--appointments {
      margin-left: 1rem;
      margin-right: 1rem;
  
      display: flex;
      flex-direction: column;
      flex: 1;
  
      // background-color: goldenrod;
  
      &__editorHeading {
        width: fit-content;
        background-color: #f5f5f5;
        padding: 0.4rem 1rem;
        border: 1px solid #b4b4b4;
        border-bottom-width: 0;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        margin-bottom: -2px;
      }
  
      @include breakpoint-up(xLarge) {
        margin-left: 3rem;
        margin-right: 3rem;
      }
  
      &__header {
        //margin-bottom: 1rem;
        display: flex;
        // justify-content: center;
        align-items: flex-start;
        @include flex-column;
  
        @include breakpoint-up(small) {
          @include flex-row;
        }
  
        &--date {
          background-color: $ethera-gray-light;
          padding: 0.3rem 1rem;
          border-radius: 30px;
          -webkit-border-radius: 30px;
          -moz-border-radius: 30px;
          -ms-border-radius: 30px;
          -o-border-radius: 30px;
          margin-right: $margin-3;
  
          & span {
            color: $black;
            font-weight: 400;
            font-size: $font-size-sm;
            white-space: nowrap;
          }
        }
  
        &--details {
          flex: 1;
          min-width: 300px;
          &__tag {
            display: flex;
            align-items: flex-start;
  
            & span {
              font-size: $font-size-lg;
              font-weight: 500;
              padding: $tag-padding;
              background-color: $ethera-brick;
              color: $ethera-text-color;
              margin-left: $margin-4;
              border-radius: 30px;
              -webkit-border-radius: 30px;
              -moz-border-radius: 30px;
              -ms-border-radius: 30px;
              -o-border-radius: 30px;
            }
  
            & p {
              margin: 0;
              font-size: $font-size-md;
              font-weight: 700;
  
              font-size: $font-size-md;
  
              @include breakpoint-up(xxSmall) {
                font-size: $font-size-h5;
              }
  
              @include breakpoint-up(xxMedLarge) {
                font-size: $font-size-h4;
              }
            }
  
            &--editComp {
              margin-bottom: 5px;
              //margin-left: $margin-2;
              @include breakpoint-up(xxSmall) {
                //margin-left: $margin-4;
              }
            }
          }
  
          &__edit {
            display: flex;
            align-items: center;
  
            & div {
              margin: 0 0.3rem 0.3rem 0.3rem;
              border: 1px solid rgba(0, 0, 0, 0.4);
              padding: 0.2rem 0.6rem;
              border-radius: 20px;
              -webkit-border-radius: 20px;
              -moz-border-radius: 20px;
              -ms-border-radius: 20px;
              -o-border-radius: 20px;
              cursor: pointer;
  
              @include breakpoint-up(xxSmall) {
                padding: 0.3rem 0.7rem;
              }
            }
          }
        }
  
        &--notes {
          margin-right: $margin-2;
          & div {
            & select {
              cursor: pointer;
              padding-top: 0.271rem !important;
              padding-bottom: 0.271rem !important;
            }
  
            @include breakpoint-up(medLg) {
              min-width: 250px;
              margin-right: $margin-4;
            }
          }
  
          @include breakpoint-down(xxSmall) {
            width: 100%;
          }
        }
  
        &--time {
          & span {
            white-space: nowrap;
          }
        }
      }
  
      &__description {
        width: 100%;
        // background-color: red;
  
        margin-left: auto;
        margin-right: auto;
  
        &--head__expand {
          cursor: pointer;
          width: 150px;
        }
  
        @include breakpoint-up(xLarge) {
          width: 77%;
          margin-left: 8rem;
        }
  
        &--head {
          &__noteHeading {
            @include client-overview-note-heading;
  
            @include breakpoint-down(xxSmall) {
              width: 100%;
            }
  
            & svg {
              margin-left: $margin-3;
            }
          }
  
          &__row {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            //margin-bottom: 1rem;
            //@include flex-column;
  
            @include breakpoint-down(xxSmall) {
              width: 100%;
            }
  
            @include breakpoint-up(med) {
              @include flex-row;
              // align-items: center;
              align-items: flex-start;
              justify-content: flex-start;
            }
          }
  
          &__expand {
            margin: 0px $margin-3;
            padding-top: 7px;
            padding-bottom: 7px;
  
            & svg {
              margin-left: $margin-2;
              background-color: $ethera-project-head;
              border-radius: 20px;
              -webkit-border-radius: 20px;
              -moz-border-radius: 20px;
              -ms-border-radius: 20px;
              -o-border-radius: 20px;
              padding: 3px;
            }
            @include breakpoint-up(med) {
              margin: 0 $margin-2;
            }
          }
  
          &__clients {
            display: flex;
            //@include flex-column;
            justify-content: flex-start;
            align-items: center;
            flex-wrap: wrap;
            width: -webkit-fill-available;
  
            //margin-left: auto !important;
            //margin-right: auto !important;
  
            @include breakpoint-up(small) {
              //@include flex-row;
              //justify-content: flex-start;
              //align-items: center;
              //width: 80%;
              margin-left: 8rem;
              margin-right: 8rem;
            }
  
            & span {
              margin-bottom: $margin-2;
              //margin-left: $margin-1;
              margin-right: $margin-2;
              background-color: $ethera-project-head;
              border: 1px solid $ethera-project-head;
              padding: 0.5rem 1rem;
              border-radius: 20px;
              -webkit-border-radius: 20px;
              -moz-border-radius: 20px;
              -ms-border-radius: 20px;
              -o-border-radius: 20px;
              white-space: nowrap;
  
              @include breakpoint-up(xSmall) {
                //margin-bottom: $margin-2;
              }
            }
            @include breakpoint-up(med) {
              //margin-left: $margin-5;
              //margin-left: 8rem;
            }
          }
        }
  
        &--soap {
          @include client-overview-note-main;
          padding-left: 6px;
          &__note {
            margin: $margin-2 0;
            border: 1px solid rgba(0, 0, 0, 0.4);
            padding: $margin-4;
            width: -webkit-fill-available;
  
            &--fragment:not(:last-child) {
              margin-bottom: $margin-5;
            }
  
            &--fragment {
              & section {
                margin-left: 24px;
              }
            }
  
            &--heading {
              display: flex;
              @include flex-row;
              align-items: center;
              justify-content: flex-start;
              margin-bottom: 1rem;
  
              & div {
                margin-left: 0 !important;
              }
            }
          }
        }
  
        &--progress {
          margin-top: 1rem;
          @include client-overview-note-main;
        }
  
        &--diagnosis {
          @include client-overview-note-main;
  
          &__note {
            & div {
              margin-bottom: $margin-1;
            }
          }
        }
  
        &--other {
          @include client-overview-note-main;
        }
      }
    }
  }