@mixin clear-fix {
    &::after {
        content: "";
        display: table;
        clear: both;
    }
}

@mixin  absCenter {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate();
    -moz-transform: translate();
    -ms-transform: translate();
    -o-transform: translate();
}

@mixin respond($breakpoint){
    @if $breakpoint == phone {
        @media only screen and (max-width: 37.5em) {  // 600px
            @content;
        }
    }
    @if $breakpoint == tab-port{
        @media only screen and (max-width: 56.25em) { // 900px
            @content;
        }
    }
    @if $breakpoint == tab-land {
        @media only screen and (max-width: 75em) { // 1200px
            @content;
        }
    }
    @if $breakpoint == big-desktop {
        @media only screen and (min-width: 112.5em) { // 1800px
            @content;
        }
    }
}
@mixin clearfix {
    &::after {
      content: '';
      display: table;
      clear: both;
    }
  }
  
  @mixin absCenter {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  
  // 640px, 1024px, 1400px
  $breakpoints-up: (
    'xxxSmall': '400px',
    'xxSmall': '500px',
    'xSmall': '600px',
    'small': '700px',
    'med': '800px',
    'medLarge': '900px',
    'large': '1000px',
    'xLarge': '1200px',
    'xxLarge': '1300px',
    'xxMLarge': '1400px',
    'xxxMLarge': '1600px',
    'xxxLarge': '1800px',
  );
  
  // 639px, 1023px, 1399px
  $breakpoints-down: (
    'xxxSmall': '400px',
    'xxSmall': '500px',
    'xSmall': '600px',
    'small': '700px',
    'med': '800px',
    'medLarge': '900px',
    'large': '1000px',
    'xLarge': '1200px',
    'xxLarge': '1300px',
    'xxMLarge': '1400px',
    'xxxMLarge': '1600px',
    'xxxLarge': '1800px',
    'xxxxLarge': '2000px',
  );
  
  @mixin breakpoint-up($size) {
    @media (min-width: map-get($breakpoints-up, $size)) {
      @content;
    }
  }
  
  @mixin breakpoint-down($size) {
    @media (max-width: map-get($breakpoints-down, $size)) {
      @content;
    }
  }
  
  @mixin reset-link {
    display: block;
    text-decoration: none;
    color: inherit;
  }
  
  @mixin reset-list {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  @mixin flex-row {
    flex-direction: row;
  }
  
  @mixin flex-column {
    flex-direction: column;
  }
  
  @mixin flex-center {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  @mixin flex-between-center {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  @mixin flex-end-center {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  
  @mixin flex-start-center {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  
  @mixin flex-row-reverse-end-center {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: flex-end;
  }
  
  @mixin flex-align-start {
    align-items: flex-start;
  }
  @mixin flex-center-start {
  display: flex;
  justify-content: center;
  align-items: flex-start;
}
@mixin client-overview-note-heading {
  display: inline-block;
  background-color: $ethera-income-head;
  padding: $text-icon-padding;
  border-radius: 20px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  -o-border-radius: 20px;
  border: 1px solid $text-icon-border;
  color: $black;
  font-weight: 600;
  text-transform: uppercase;
  width: fit-content;
  overflow: hidden;
  text-overflow: ellipsis;
}
@mixin client-overview-note-main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: -webkit-fill-available;
  //margin-left: auto !important;
  //margin-right: auto !important;

  @include breakpoint-up(small) {
    //width: 80%;
    margin-left: 8rem;
    margin-right: 8rem;
  }
}
