.file-details {
    display: flex;
    justify-content: center;
    .file-preview{
      position: relative;
      width: fit-content;
      .uploader-image{
        border-radius: 12px;
      }
      .uploader-image-detail{
        display: none;
        position: absolute;
        // transform: scale(1);
        text-align: center;
        top: 0;
        bottom: 4px;
        width: -webkit-fill-available;
        height: -webkit-fill-available;
        justify-content: center;
        align-items: center;
        border-radius: 12px;
        background-color: $black;
        opacity: 0.7;
        color: $white !important;
        -webkit-transform: scale(0);
        -ms-transform: scale(0);
        transform: scale(0);
        -webkit-transition: .3s ease;
        transition: .3s ease;
        overflow: hidden;
        .uploader-detail-box{
        display: flex;
        justify-content: center;
        align-items: center;
        height: -webkit-fill-available;
        .text{
          padding: 0 0.5rem;
          font-weight: bold; 
          opacity: 1;
        }
        }
        .cancel-uploader-image{
          display: flex;
          right: 0;
          float: right !important;
          margin: 0.6rem 0.5rem;
          position: absolute;
          margin-left: auto;
          width: fit-content;
          cursor: pointer;
          padding: 0.2rem;
          background-color: $white !important;
          border-radius: 50%;
        }
        
      }
    }
  }
  
  .file-preview:hover .uploader-image-detail{
    display: block;
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
  }
  