.p-x-3 {
  padding-left: 2rem;
  padding-right: 2rem;

  @include breakpoint-up(xxSmall) {
    padding-left: 3rem;
    padding-right: 3rem;
  }
}

.p-x-2 {
  padding-left: 1rem;
  padding-right: 1rem;

  @include breakpoint-up(xxSmall) {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
