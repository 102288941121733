// Headings
.heading-1 {
    font-size: 18px;
    font-weight: 800;
  
    @include breakpoint-up(xxSmall) {
      font-size: 24px;
    }
  }
  
  .heading-2 {
    font-size: 16px;
    font-weight: 700;
  
    @include breakpoint-up(xxSmall) {
      font-size: 22px;
    }
  
  }
  
  .heading-3 {
    font-size: 14px;
    font-weight: 600;
  
    @include breakpoint-up(xxSmall) {
      font-size: 20px;
    }
  }
  
  .heading-4 {
    font-size: 12px;
    font-weight: 500;
  
    @include breakpoint-up(xxSmall) {
      font-size: 18px;
    }
  }
  
  .heading-5 {
    font-size: 12px;
    font-weight: 700;
  
    @include breakpoint-up(xxSmall) {
      font-size: 16px;
    }
  }

  .f-body-regular {
    font-size: $font-size-small;
    font-weight: 400;

    @include breakpoint-up(xxSmall) {
      font-size: $font-size-med;
    }
  }

  
  .heading-1, .heading-2, .heading-3, .heading-4, .heading-5, .sub-heading-1, .sub-heading-2 {
    color: $black;
  }
  
  // Sub-heading
  .sub-heading-1 {
    font-size: 14px;
  
    @include breakpoint-up(xxSmall) {
      font-size: 18px;
    }
  }
  
  .sub-heading-2 {
    font-size: 10px;
  
    @include breakpoint-up(xxSmall) {
      font-size: 14px;
    }
  }
  // spans
  .span-tabs {
    font-size: 15px;
    font-weight: 500;
  }
  
  .p-heading-2 {
    font-size: 10px;
  
    @include breakpoint-up(xxSmall) {
      font-size: 14px;
    }
  }
  
  .label-2 {
    // color: $black;
    font-weight: 500;
    font-size: 14px;
  }
  
  // fonts-sizes

.fs-x-small {
  font-size: $font-size-x-small;
}

.fs-small {
  font-size: $font-size-small;
}

.fs-s-med {
  font-size: $font-size-s-med;
}

.fs-med {
  font-size: $font-size-med;
}

.fs-l-med {
  font-size: $font-size-l-med;
}

.fs-large {
  font-size: $font-size-large;
}

.fs-x-large {
  font-size: $font-size-x-large;
}

.fs-xx-large {
  font-size: $font-size-xx-large;
}

.fs-xxx-large {
  font-size: $font-size-xxx-large;
}

.f-bold {
  font-weight: bold;
}

.fs-12 {
  font-size: 12px;
}