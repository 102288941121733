.billingCard {
  background-color: $bg-ethera;
  .billingCardHeader {
    padding-bottom: 0rem !important;
    display: block;
  }
  .billingCardBody {
    padding: 1.5rem 0rem;
  }
  .billingHeader {
    .editCol {
      padding-right: 0 !important;
    }
    .input-group-text {
      border-radius: 20px;
      border-left: none;
    }
    .Billingselect {
      border-radius: 20px;
      font-size: 10px;
      margin: 1rem 0rem;
      padding: 0.3rem 1rem !important;
    }
    .searchDiv {
      border-radius: 20px;
      margin: 1rem 0rem;

      .billingSearch {
        font-size: 10px;
        border-top-left-radius: 20px !important;
        border-bottom-left-radius: 20px !important;
        border-right: none;
        border: none;

        padding: 0.3rem 1rem !important;
      }
    }
    .searchIcon {
      background: #fff;
      padding: 1px 11px 1px 1px;
      margin-left: -3px;
      border-bottom-right-radius: 20px;
      border-top-right-radius: 20px;
    }

    .billingBtnDiv {
      margin: 1rem 0rem;

      .billingEditBtn {
        padding: 0.45rem 2rem;
        font-size: 15px;
        font-weight: 400;
        background-color: $white !important;
        color: $black !important;
        white-space: nowrap;
        margin-left: 1rem;

        & svg {
          margin-right: 0.5rem;
        }
      }
    }
  }
}
